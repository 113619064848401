import React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";

const HelpButton = props => (
    <span className="help-button">
        <span
            {...props}
        >
            <QuestionCircleOutlined />
        </span>
    </span>
);

export default HelpButton;
