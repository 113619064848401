import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import i18n from "../../core/i18n";

export const Meta = ({ translateKey }) => {
    const router = useRouter(); 
    const canonicalUrl = `https://booking.prague-airport-transfers.co.uk${router.asPath}`;

    const isProduction = process.env.APP_ENV === 'production';

    return (
        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=1" />
            <title>{i18n.t(`${translateKey}-title`)}</title>
            <meta name="description" content={i18n.t(`${translateKey}-description`)} />
            {isProduction ? (
                <>
                    <link rel="canonical" href={canonicalUrl} /> 
                    <meta name="robots" content="index, follow" /> 
                </>
            ) : (
                <meta name="robots" content="noindex, nofollow" />
            )}
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />

            {/* Open Graph Tags */}
            <meta property="og:title" content={i18n.t(`${translateKey}-title`)} />
            <meta property="og:description" content={i18n.t(`${translateKey}-description`)} />
            <meta property="og:url" content={canonicalUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://booking.prague-airport-transfers.co.uk/img/logo.svg" />

            {/* Twitter Card Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@PragueATransfer" />
            <meta name="twitter:title" content={i18n.t(`${translateKey}-title`)} />
            <meta name="twitter:description" content={i18n.t(`${translateKey}-description`)} />
            <meta name="twitter:image" content="https://booking.prague-airport-transfers.co.uk/img/logo.svg" />

            {/* Structured data for SEO: Organization and Social Media Profiles */}
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "PRAGUE AIRPORT TRANSFERS, s.r.o.",
                        "url": "https://www.prague-airport-transfers.co.uk/",
                        "logo": "https://booking.prague-airport-transfers.co.uk/img/logo.svg",
                        "sameAs": [
                            "https://twitter.com/PragueATransfer",
                            "https://www.instagram.com/pragueairportshuttle/",
                            "https://www.youtube.com/user/guidetoprague"
                        ],
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Velvarská 1701/25",
                            "addressLocality": "Prague 6",
                            "addressRegion": "PR",
                            "postalCode": "160 00",
                            "addressCountry": "CZ",
                            "name": "PRAGUE AIRPORT TRANSFERS, s.r.o."
                        },
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+420222554211",
                            "contactType": "Bookings and questions",
                            "email": "info@prague-airport-transfers.co.uk",
                            "areaServed": "CZ",
                            "availableLanguage": ["English", "Czech", "Russian"]
                        },
                        "openingHours": "Mo-Su 07:00-23:00",
                        "foundingDate": "2006-09-13",
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "50.099215648492034",
                            "longitude": "14.382126399615418"
                        }
                    }),
                }}
            />
        </Head>
    );
};
