import React from "react";
import PropTypes from "prop-types";
import { Pagination } from "antd";

const Paginator = ({ store }) => {
    if (store.paginator.itemsCount === 0) {
        return null;
    }

    return (
        <div style={{ textAlign: "center", margin: "25px 0 10px 0" }}>
            <Pagination
                onChange={(page,pageSize) => { store.changePage(page, pageSize); }}
                current={store.paginator.page}
                //showTotal={(total, range) => `${range[0]}-${range[1]} z ${total} položek`}
                pageSize={store.paginator.itemsPerPage}
                total={store.paginator.itemsCount}
            />
        </div>
    );
};

Paginator.propTypes = {
    store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Paginator;
