import React, { Component } from "react";
import { InputNumber } from "antd";
import PropTypes from "prop-types";

class InputInt extends Component { // eslint-disable-line react/prefer-stateless-function
    static propTypes = {
        onChange: PropTypes.func,
    };

    static defaultProps = {
        onChange: () => {},
    };

    render() {
        return (
            <InputNumber
                {...this.props}
                onChange={(value) => {
                    const { onChange } = this.props;
                    onChange(parseInt(value, 10) || 0);
                }}
                type="number"
            />
        );
    }
}

export default InputInt;
