import moment from 'moment-timezone';
import NewOrderStore from '../../stores/Order/NewOrderStore';
import UserListStore from '../../stores/User/UserListStore';
import { TotalPriceDto } from '../../dtos/totalPriceDto';
import CurrencyStore from '../../stores/Currency/CurrencyStore';

const getTotalPrice = () => {
    let discountedFromPrice = 0;
    let discountedToPrice = 0;
    let surchargesFromPrice = 0;
    let surchargesToPrice = 0;
    let extraDiscountedPrice = 0;

// Check if the currency is JPY
const isJPY = CurrencyStore.selectedCurrency === CurrencyStore.JPY;

    if (NewOrderStore.isForPricing) {
        return {
            extrasPriceThereRoute: 0,
            extrasPriceThereRouteForeignCurrency: 0,
            extrasPriceBackRoute: 0,
            extrasPriceBackRouteForeignCurrency: 0,
            extrasTotalPriceForeignCurrency: 0,
            extrasTotalPrice: 0,
            extrasForBackRoute: 0,
            priceCarsEnd: 0,
            priceCarsStart: 0,
            discountedFromPrice: 0,
            discountedToPrice: 0,
            surchargesFromPrice: 0,
            surchargesToPrice: 0,
            getStart: 0,
            getEnd: 0,
            totalPrice: 0,
            extraDiscountedPrice: 0,
        };
    }

    const checkDiscountFrom = (selectedCar) => {
        if (!selectedCar) return false;

        const dates = NewOrderStore.getFormattedPickupDates();
        const pickupDate = moment(dates.pickupDateTime);

        const discountMinDate = moment(selectedCar.thereDiscountMinDate);
        const discountMaxDate = moment(selectedCar.thereDiscountMaxDate);

        return pickupDate.isBetween(discountMinDate, discountMaxDate, null, '[]');
    };

    const checkSurchargesFrom = (selectedCar) => {
        if (!selectedCar) return false;

        const dates = NewOrderStore.getFormattedPickupDates();
        const pickupDate = moment(dates.pickupDateTime);

        const surchargesMinDate = moment(selectedCar.thereSurchargesMinDate);
        const surchargesMaxDate = moment(selectedCar.thereSurchargesMaxDate);

        return pickupDate.isBetween(surchargesMinDate, surchargesMaxDate, null, '[]');
    };

    const checkDiscountTo = (selectedCar) => {
        if (!selectedCar) return false;

        const dates = NewOrderStore.getFormattedPickupDates();
        const returnPickupDate = moment(dates.returnPickupDateTime);

        const discountMinDate = moment(selectedCar.backDiscountMinDate);
        const discountMaxDate = moment(selectedCar.backDiscountMaxDate);

        return returnPickupDate.isBetween(discountMinDate, discountMaxDate, null, '[]');
    };

    const checkSurchargesTo = (selectedCar) => {
        if (!selectedCar) return false;

        const dates = NewOrderStore.getFormattedPickupDates();
        const returnPickupDate = moment(dates.returnPickupDateTime);

        const surchargesMinDate = moment(selectedCar.backSurchargesMinDate);
        const surchargesMaxDate = moment(selectedCar.backSurchargesMaxDate);

        return returnPickupDate.isBetween(surchargesMinDate, surchargesMaxDate, null, '[]');
    };

    const addNoCombinationPrices = (priceDto, carCategory) => {
        carCategory.combination.forEach((combination) => {
            priceDto.addStartPrice(combination.price * combination.carCount);

            if (NewOrderStore.backRoute) {
                priceDto.addEndPrice(combination.price * combination.carCount);
            }
        });
    };

    const addThereRouteDiscountAndSurcharges = (priceDto, carCategory) => {
        if (checkDiscountFrom(carCategory) && checkSurchargesFrom(carCategory)) {
            const discount = carCategory?.thereDiscount * carCategory?.carCount || 0;
            const surcharges = carCategory?.thereSurcharges * carCategory?.carCount || 0;
            const price = carCategory?.price || 0;
            const surchargesMinusPrice = surcharges - price;
            const discountPlusSurchargesMinusPrice = discount + surchargesMinusPrice;

            priceDto.addStartPrice(discountPlusSurchargesMinusPrice * carCategory?.carCount || 0);
            priceDto.addDiscountFrom(discount);
            priceDto.addSurchargesFrom(surchargesMinusPrice);
        } else if (checkDiscountFrom(carCategory)) {
            const discount = carCategory?.thereDiscount || 0;

            priceDto.addStartPrice(discount * carCategory?.carCount || 0);
            priceDto.addDiscountFrom(discount * carCategory?.carCount || 0);
        } else if (checkSurchargesFrom(carCategory)) {
            const surcharges = carCategory?.thereSurcharges || 0;
            const price = carCategory?.price || 0;
            const surchargesMinusPrice = surcharges - price;

            priceDto.addStartPrice(surcharges * carCategory?.carCount || 0);
            priceDto.addSurchargesFrom(surchargesMinusPrice * carCategory?.carCount || 0);
        } else {
            const price = carCategory?.price || 0;
            priceDto.addStartPrice(price * carCategory?.carCount || 0);
        }
    };

    const addBackRouteDiscountAndSurcharges = (priceDto, carCategory) => {
        if (checkDiscountTo(carCategory) && checkSurchargesTo(carCategory)) {
            const discount = carCategory?.backDiscount || 0;
            const surcharges = carCategory?.backSurcharges || 0;
            const price = carCategory?.price || 0;
            const surchargesMinusPrice = surcharges - price;
            const discountPlusSurchargesMinusPrice = discount + surchargesMinusPrice;

            priceDto.addEndPrice(discountPlusSurchargesMinusPrice * carCategory?.carCount || 0);
            priceDto.addDiscountTo(discount * carCategory?.carCount || 0);
            priceDto.addSurchargesTo(surchargesMinusPrice * carCategory?.carCount || 0);
        } else if (checkDiscountTo(carCategory)) {
            const discount = carCategory?.backDiscount || 0;

            priceDto.addEndPrice(discount * carCategory?.carCount || 0);
            priceDto.addDiscountTo(priceDto.getEndPrice());
        } else if (checkSurchargesTo(carCategory) && carCategory.discountPercentage && carCategory.isreturnDiscount) {
            const price = carCategory?.price * carCategory?.carCount || 0;

            // sleva
            const carCategoryDiscount = (price * parseFloat(carCategory.discountPercentage)) / 100;
            const priceMinusCarCategoryDiscount = price - carCategoryDiscount;
            priceDto.addDiscountTo(priceMinusCarCategoryDiscount);

            // příplatek
            const surcharges = carCategory?.backSurcharges * carCategory?.carCount || 0;
            const surchargesMinusPrice = surcharges - price;
            priceDto.addSurchargesTo(surchargesMinusPrice);

            // konečná cena
            const endPrice = Number(priceMinusCarCategoryDiscount) + surchargesMinusPrice;

            priceDto.addEndPrice(endPrice);
        } else if (checkSurchargesTo(carCategory)) {
            const surcharges = carCategory?.backSurcharges * carCategory?.carCount || 0;
            const price = carCategory?.price * carCategory?.carCount || 0;
            const surchargesMinusPrice = surcharges - price;

            priceDto.addEndPrice(surcharges || 0);
            priceDto.addSurchargesTo(surchargesMinusPrice);
        } else if (!checkDiscountTo(carCategory) && carCategory.isreturnDiscount && UserListStore.user && !UserListStore.user.isPartner) {
            const price = carCategory?.price || 0;
            const carCategoryDiscount = (price * parseFloat(carCategory.discountPercentage)) / 100;
            const priceMinusExtraDiscount = price - carCategoryDiscount;
            extraDiscountedPrice += priceMinusExtraDiscount * carCategory?.carCount;
        
            priceDto.addEndPrice(priceMinusExtraDiscount * carCategory?.carCount || 0);
        } else {
            const price = carCategory?.price || 0;

            priceDto.addEndPrice(price * carCategory?.carCount || 0);
        }
    };

    const extrasPriceThereRoute = NewOrderStore.calculateExtrasPriceThereRoute();
    const extrasPriceThereRouteForeignCurrency = NewOrderStore.calculateExtrasPriceThereRouteForeignCurrency();
    const extrasPriceBackRoute = NewOrderStore.calculateExtrasPriceBackRoute();
    const extrasPriceBackRouteForeignCurrency = NewOrderStore.calculateExtrasPriceBackRouteForeignCurrency();
    const extrasForBackRoute = NewOrderStore.returnRouteMode ? extrasPriceBackRoute : extrasPriceThereRoute;
    const extrasTotalPrice = extrasPriceThereRoute + extrasPriceBackRoute;
    const extrasTotalPriceForeignCurrency = (extrasPriceThereRouteForeignCurrency + extrasPriceBackRouteForeignCurrency).toFixed(2);
    const priceCarsStart = (NewOrderStore.returnRouteMode ? NewOrderStore.startPriceCars : NewOrderStore.priceCars);
    const priceCarsEnd = (NewOrderStore.endPriceCars || NewOrderStore.priceCars);
    let totalPrice;

    const priceDto = new TotalPriceDto();

    if (NewOrderStore.getRouteWay() === "thereRoute" && !NewOrderStore.backRoute && NewOrderStore.mode !== "hour-rental"
    && NewOrderStore.mode !== "excursion" && NewOrderStore.selectedCars[NewOrderStore.getRouteWay()]) {
        NewOrderStore.selectedCars[NewOrderStore.getRouteWay()].forEach((carCategory) => {
            if (carCategory?.combination) {
                addNoCombinationPrices(priceDto, carCategory);
            } else {
                addThereRouteDiscountAndSurcharges(priceDto, carCategory);
            }
        });
    } else {
        if (NewOrderStore.selectedCars.thereRoute) {
            NewOrderStore.selectedCars.thereRoute.forEach((carCategory) => {
                if (carCategory?.combination) {
                    addNoCombinationPrices(priceDto, carCategory);
                } else {
                    addThereRouteDiscountAndSurcharges(priceDto, carCategory);
                }
            });
        }
        if (NewOrderStore.selectedCars.backRoute) {
            NewOrderStore.selectedCars.backRoute.forEach((carCategory) => {
                if (carCategory?.combination) {
                    addNoCombinationPrices(priceDto, carCategory);
                } else {
                    addBackRouteDiscountAndSurcharges(priceDto, carCategory);
                }
            });
        }
    }

    if (!NewOrderStore.backRoute) {
        totalPrice = priceDto.getStartPrice() + extrasTotalPrice;
        discountedFromPrice = priceDto.getDiscountFrom();
        surchargesFromPrice = priceDto.getSurchargesFrom();
    } else {
        let flagCombination = false;
        NewOrderStore.selectedCars.backRoute.forEach((carCategory) => {
            if (carCategory?.combination) {
                flagCombination = true;
            }
        });
        if (flagCombination) {
            const fx = priceDto.getStartPrice() + priceDto.getEndPrice() + extrasTotalPrice;
            totalPrice = fx / 2;
        } else {
            totalPrice = priceDto.getStartPrice() + priceDto.getEndPrice() + extrasTotalPrice;
        }

        discountedFromPrice = priceDto.getDiscountFrom();
        surchargesFromPrice = priceDto.getSurchargesFrom();
        discountedToPrice = priceDto.getDiscountTo();
        surchargesToPrice = priceDto.getSurchargesTo();
        
    }

    return {
        extrasPriceThereRoute: isJPY ? Math.round(extrasPriceThereRoute) : extrasPriceThereRoute,
        extrasPriceThereRouteForeignCurrency,
        extrasPriceBackRoute: isJPY ? Math.round(extrasPriceBackRoute) : extrasPriceBackRoute,
        extrasPriceBackRouteForeignCurrency,
        extrasTotalPriceForeignCurrency,
        extrasTotalPrice: isJPY ? Math.round(extrasTotalPrice) : extrasTotalPrice,
        extrasForBackRoute: isJPY ? Math.round(extrasForBackRoute) : extrasForBackRoute,
        priceCarsEnd: isJPY ? Math.round(priceCarsEnd) : Math.round(priceCarsEnd),
        priceCarsStart: isJPY ? Math.round(priceCarsStart) : Math.round(priceCarsStart),
        discountedFromPrice: isJPY ? Math.round(discountedFromPrice) : Math.round(discountedFromPrice),
        discountedToPrice: isJPY ? Math.round(discountedToPrice) : Math.round(discountedToPrice),
        surchargesFromPrice: isJPY ? Math.round(surchargesFromPrice) : Math.round(surchargesFromPrice),
        surchargesToPrice: isJPY ? Math.round(surchargesToPrice) : Math.round(surchargesToPrice),
        getStart: isJPY ? Math.round(priceDto.getStartPrice()) : Math.round(priceDto.getStartPrice()),
        getEnd: isJPY ? Math.round(priceDto.getEndPrice()) : Math.round(priceDto.getEndPrice()),
        totalPrice: isJPY ? Math.round(totalPrice) : Math.round(totalPrice),
        extraDiscountedPrice: isJPY ? Math.round(extraDiscountedPrice) : Math.round(extraDiscountedPrice),
    };
};

export default getTotalPrice;
