export class TotalPriceDto {
    startPrice = 0;

    endPrice = 0;

    discountFrom = 0;

    discountTo = 0;

    surchargesFrom = 0;

    surchargesTo = 0;

    addStartPrice(startPrice) {
        this.startPrice += startPrice;
    }

    getStartPrice() {
        return this.startPrice;
    }

    addEndPrice(endPrice) {
        this.endPrice += endPrice;
    }

    getEndPrice() {
        return this.endPrice;
    }

    addDiscountFrom(discountFrom) {
        this.discountFrom += discountFrom;
    }

    getDiscountFrom() {
        return this.discountFrom;
    }

    addDiscountTo(discountTo) {
        this.discountTo += discountTo;
    }

    getDiscountTo() {
        return this.discountTo;
    }

    addSurchargesFrom(surchargesFrom) {
        this.surchargesFrom += surchargesFrom;
    }

    getSurchargesFrom() {
        return this.surchargesFrom;
    }

    addSurchargesTo(surchargesTo) {
        this.surchargesTo += surchargesTo;
    }

    getSurchargesTo() {
        return this.surchargesTo;
    }
}
